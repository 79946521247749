import compact from "lodash/compact";
import getConfig from "next/config";
import {
  accommodationFacilitiesLayer,
  actEsaResourcesLayer,
  agedCareLayer,
  aircraftLayer,
  airportsLayer,
  alertStatusDistrictTodayLayer,
  alertStatusStateTodayLayer,
  avlDataLayer,
  avlEmergencyLayer,
  burntAreaCurrentFireSeasonLayer,
  burntAreaHazardReductionLayer,
  communicationTowersLayer,
  continuousHaines,
  deaHotspotsLayer,
  educationFacilitiesLayer,
  emergencyAlertsLayer,
  emergencyFacilitiesLayer,
  fesmFourToSevenYearsLayer,
  fesmOneToThreeYearsLayer,
  fesmSevenPlusYearsLayer,
  fieldObsIconUnder1HrLayer,
  fieldObsIconUnder3HrsLayer,
  fieldObsSitRepUnder1HrLayer,
  fieldObsSitRepUnder3HrsLayer,
  fieldObsWeatherUnder1HrLayer,
  fieldObsWeatherUnder3HrsLayer,
  fireDangerRating,
  fireGroundFlightRiskLayer,
  fireMapper1HrLayer,
  fireMapper24HrsLayer,
  fireMapper3HrsLayer,
  fireTrailsLayer,
  fourToSevenYearsLayer,
  frnswJurisdictionalBoundariesLayer,
  fuelTypeLayer,
  governmentRadioNetworkLayer,
  habitablePropertiesLayer,
  healthFacilitiesLayer,
  himawariSurfaceTemperature,
  interstateIncidentsLayer,
  landUseLayer,
  lightningLayer,
  linescanLayer,
  liveTrafficLayer,
  lnvLayer,
  longwaveInfraredLayer,
  mediumwaveInfraredLayer,
  mediumwaveLongwaveInfraredLayer,
  mixingHeight,
  mobileBlackspotsLayer,
  nearInfraredLayer,
  neighbourhoodSaferPlacesLayer,
  oilAndGasPipelinesLayer,
  oneToThreeYearsLayer,
  powerLayer,
  rartStandbyTodayLayer,
  redMapLayer,
  relativeHumidity,
  rfsBrigades,
  rfsDistricts,
  rtaIncidents,
  sevenPlusYearsLayer,
  seventyFivePercentChancePrecipitation,
  shortwaveInfraredLayer,
  siteInformationLayer,
  socialMedia,
  soviAggregationLayer,
  stateForestPlantationLayer,
  telephoneExchangeLayer,
  temperature,
  thermalCompositeLayer,
  totalFireBanAreasLayer,
  totalFuelLoadLayer,
  twentyFivePercentChancePrecipitation,
  validatedSocialMedia,
  visibleImageLayer,
  visibleNirLayer,
  windChangeDangerFlag,
  windChangeDangerIndex,
  windDirection,
  windDirection1500MElevation,
  windGustKmH,
  windSpeed1500MElevationKmH,
  windSpeedKmH,
  type LayerConfig,
  type LayerSet,
  publicSafetyNetworkLayer,
  mobileCoverageOptusLayer,
  mobileCoverageTelstraLayer,
  mobileCoverageTpgLayer,
} from "./layers";

const { publicRuntimeConfig } = getConfig();
const {
  AVIATION_SAFETY_ENABLED,
  COP_ENABLED,
  DYNAMIC_COVERAGE_ENABLED,
  FIRE_MAPPER_LAYERS_ENABLED,
} = publicRuntimeConfig;

interface CreateLayerSetParams {
  id: string;
  label: string;
  layers: LayerConfig[];
}

const createLayerSet = ({
  id,
  label,
  layers,
}: CreateLayerSetParams): LayerSet => ({
  id,
  label,
  layers: layers.map((layerBase) => ({
    ...layerBase,
    type: id,
  })),
});

const weatherLayerSet = createLayerSet({
  id: "weather",
  label: "Weather",
  layers: compact([
    continuousHaines,
    deaHotspotsLayer,
    fireDangerRating,
    temperature,
    lightningLayer,
    mixingHeight,
    relativeHumidity,
    himawariSurfaceTemperature,
    windChangeDangerIndex,
    windChangeDangerFlag,
    windDirection,
    windDirection1500MElevation,
    windGustKmH,
    windSpeedKmH,
    windSpeed1500MElevationKmH,
    twentyFivePercentChancePrecipitation,
    seventyFivePercentChancePrecipitation,
  ]),
});

const fuelConditionsLayerSet = createLayerSet({
  id: "fuelConditions",
  label: "Fuel Conditions",
  layers: compact([
    fesmOneToThreeYearsLayer,
    fesmFourToSevenYearsLayer,
    fesmSevenPlusYearsLayer,
    oneToThreeYearsLayer,
    fourToSevenYearsLayer,
    sevenPlusYearsLayer,
    burntAreaCurrentFireSeasonLayer,
    COP_ENABLED && burntAreaHazardReductionLayer,
    fuelTypeLayer,
    totalFuelLoadLayer,
  ]),
});

const assetsAndInfrastructureLayerSet = createLayerSet({
  id: "assetsAndInfrastructure",
  label: "Assets & Infrastructure",
  layers: compact([
    COP_ENABLED && stateForestPlantationLayer,
    accommodationFacilitiesLayer,
    agedCareLayer,
    educationFacilitiesLayer,
    habitablePropertiesLayer,
    healthFacilitiesLayer,
    airportsLayer,
    communicationTowersLayer,
    governmentRadioNetworkLayer,
    mobileBlackspotsLayer,
    oilAndGasPipelinesLayer,
    powerLayer,
    COP_ENABLED && telephoneExchangeLayer,
    emergencyFacilitiesLayer,
    mobileCoverageOptusLayer,
    mobileCoverageTelstraLayer,
    mobileCoverageTpgLayer,
    publicSafetyNetworkLayer,
  ]),
});

const resourceTrackingLayerSet = createLayerSet({
  id: "resourceTracking",
  label: "Resource Tracking",
  layers: compact([
    aircraftLayer,
    COP_ENABLED && actEsaResourcesLayer,
    avlDataLayer,
    COP_ENABLED && avlEmergencyLayer,
  ]),
});

const fieldObservationsLayerSet = createLayerSet({
  id: "fieldObservations",
  label: "Field Observations",
  layers: compact([
    COP_ENABLED && fieldObsIconUnder1HrLayer,
    COP_ENABLED && fieldObsIconUnder3HrsLayer,
    COP_ENABLED && fieldObsSitRepUnder1HrLayer,
    COP_ENABLED && fieldObsSitRepUnder3HrsLayer,
    COP_ENABLED && fieldObsWeatherUnder1HrLayer,
    COP_ENABLED && fieldObsWeatherUnder3HrsLayer,
    FIRE_MAPPER_LAYERS_ENABLED && fireMapper1HrLayer,
    FIRE_MAPPER_LAYERS_ENABLED && fireMapper3HrsLayer,
    FIRE_MAPPER_LAYERS_ENABLED && fireMapper24HrsLayer,
  ]),
});

const planningLayerSet = createLayerSet({
  id: "planning",
  label: "Planning",
  layers: compact([
    COP_ENABLED && neighbourhoodSaferPlacesLayer,
    rartStandbyTodayLayer,
    alertStatusDistrictTodayLayer,
    alertStatusStateTodayLayer,
    fireTrailsLayer,
    landUseLayer,
  ]),
});

const aerialImageryLayerSet = createLayerSet({
  id: "aerialImagery",
  label: "Aerial Imagery",
  layers: compact([
    COP_ENABLED && linescanLayer,
    thermalCompositeLayer,
    shortwaveInfraredLayer,
    mediumwaveInfraredLayer,
    mediumwaveLongwaveInfraredLayer,
    longwaveInfraredLayer,
    lnvLayer,
    nearInfraredLayer,
    visibleImageLayer,
    COP_ENABLED && visibleNirLayer,
  ]),
});

const socialIntelLayerSet = createLayerSet({
  id: "socialIntel",
  label: "Social Intel",
  layers: compact([socialMedia]),
});

const situationAwarenessLayerSet = createLayerSet({
  id: "situationAwareness",
  label: "Situation Awareness",
  layers: compact([
    COP_ENABLED && interstateIncidentsLayer,
    liveTrafficLayer,
    COP_ENABLED && rtaIncidents,
    soviAggregationLayer,
    AVIATION_SAFETY_ENABLED && fireGroundFlightRiskLayer,
    redMapLayer,
    COP_ENABLED && totalFireBanAreasLayer,
    COP_ENABLED && emergencyAlertsLayer,
    DYNAMIC_COVERAGE_ENABLED && rfsBrigades,
  ]),
});

const situationAwarenessCopLayerSet = createLayerSet({
  id: "situationAwareness",
  label: "Situation Awareness",
  layers: compact([
    COP_ENABLED && interstateIncidentsLayer,
    liveTrafficLayer,
    COP_ENABLED && rtaIncidents,
    soviAggregationLayer,
    redMapLayer,
    COP_ENABLED && totalFireBanAreasLayer,
    COP_ENABLED && emergencyAlertsLayer,
  ]),
});

const validatedSocialIntelLayerSet = createLayerSet({
  id: "validatedSocialIntel",
  label: "Social Intel",
  layers: compact([validatedSocialMedia]),
});

// NOTE: These are different from the MapBox base map, just another category
const baseMapsLayerSet = createLayerSet({
  id: "baseMaps",
  label: "Base Maps",
  layers: compact([
    rfsDistricts,
    frnswJurisdictionalBoundariesLayer,
    COP_ENABLED && siteInformationLayer,
  ]),
});

const commonLayerSets = [
  weatherLayerSet,
  fuelConditionsLayerSet,
  assetsAndInfrastructureLayerSet,
  resourceTrackingLayerSet,
  fieldObservationsLayerSet,
  planningLayerSet,
  aerialImageryLayerSet,
  situationAwarenessLayerSet,
  baseMapsLayerSet,
] satisfies LayerSet[];

export const aviationSafetyLayerSets = [
  ...commonLayerSets,
] satisfies LayerSet[];
export const fireModellingLayerSets = [...commonLayerSets] satisfies LayerSet[];
export const incidentLayerSets = [
  ...commonLayerSets,
  socialIntelLayerSet,
] satisfies LayerSet[];
export const socialIncidentLayerSets = [
  ...commonLayerSets,
] satisfies LayerSet[];

export const copLayerSets = [
  weatherLayerSet,
  fuelConditionsLayerSet,
  assetsAndInfrastructureLayerSet,
  resourceTrackingLayerSet,
  fieldObservationsLayerSet,
  planningLayerSet,
  aerialImageryLayerSet,
  situationAwarenessCopLayerSet,
  baseMapsLayerSet,
  validatedSocialIntelLayerSet,
] satisfies LayerSet[];

export const uploadLayerSets = [];
